import React from 'react';

function Footer() {
	return (
		<footer>
			<div className="container">
				<p>Copyright &copy; 2021 CV - TAKKA Karim - Tous droits réservés.</p>
			</div>
		</footer>
	);
}

export default Footer;
