import { useState } from 'react';
import React from 'react';

function Cardformation(props) {
	const [ toggle, setToggle ] = useState(false);
	const activToggle = (e) => {
		e.preventDefault();
		setToggle(!toggle);
	};
	//console.log(props);
	return (
		 <>
			
				
						<div key={props.id} className="cardFormation text-center text-wrap">
							<div className="cardBody">
								<div className=" cardimg text-center ">
									<img onClick={activToggle} className="item img-fluid" src={props.logo} alt="" />
								</div>
								<h3>{props.titre}</h3>
								<h4>{props.etablissement}</h4>
								<p>{props.contenu}</p>
								<p>{props.realisation}</p>
							</div>
							{toggle && (
								<div key={props.id} className={`viewpictures active`}>
									<img className="diplome active"  onClick={activToggle} src={props.photo} alt="" />
								</div>
							)}
						</div>
				
		

			
		</>
	);
}

export default Cardformation;
