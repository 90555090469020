import { useState } from "react";
import React  from 'react';

function Listeprojets(props) {
	//console.log(props);
	const [toggel, setToggel] = useState(false);
	const [show, setShow] = useState(false);
	const showVideo = ()=>{
		setShow(!show);
	}
	
	

	return (
		<div  key={props.id} className="col-md-4">
			<div className="card text-center m-2">
				<div className="divlogo text-center mb-2 bg-info">
					<i className={props.logo} />
				</div>
				<img src={props.img} className=" img-fluid w-75 card-img-top" alt="projet portfolio" />
				<div className="card-body">
					<h5 className="card-title">{props.titre}</h5>
					{
						toggel && (
							<div className="hidecard" style={{transition:"2s"}} >
						<h6 className="card-subtitle mb-2 ">{props.soustitre}</h6>
						<p className="card-text">{props.contenu}</p>
							{
								(()=>{
									switch(props.titre){
										case "Réalisation d'une API NodeJs": 
										return (
											<>
											<a href={props.url} 
											className="btn m-1" 
											target="_blank" 
											rel="noopener noreferrer">
											Voir le projet
											</a>
											<a
											href={props.codeSource}
											className="btn m-1"
											target="_blank"
											rel="noopener noreferrer"
											>
											Code source
											</a>
											<a
											href={props.document}
											className="btn m-2"
											target="_blank"
											rel="noopener noreferrer"
											>
											Document
											</a>
											</>
											)
										case "Projet Angular connecter avec notre API": 
										return (
											<>
											<a href={props.url} 
											className="btn m-3" 
											target="_blank" 
											rel="noopener noreferrer">
											Voir le projet
											</a>
											<a
											href={props.codeSource}
											className="btn m-2"
											target="_blank"
											rel="noopener noreferrer"
											>
											Code source
											</a>
											</>
										)

										case "Référencement SEO" :
										return(
											<>
											<a href={props.url} 
											className="btn m-2" 
											target="_blank" 
											rel="noopener noreferrer">
											Voir le projet
											</a>
											<a
											href={props.document}
											className="btn m-2"
											target="_blank"
											rel="noopener noreferrer"
											>
											Document
											</a>
											</>			
										)
										case "Projet WordPress" :
										return(
											<a href={props.url} 
						className="btn  m-3" 
						target="_blank" 
						rel="noopener noreferrer">
							Voir le projet
						</a>

										)
										case "Projet Symfony SQL":
											return(
												<>
												<button
												onClick={showVideo}
											 
						className="btn m-3" 
						target="_blank" 
						rel="noopener noreferrer">
							Voir le projet
						</button>
						<a
							href={props.codeSource}
							className="btn m-2"
							target="_blank"
							rel="noopener noreferrer"
						>
							Code source
						</a>
						</>
											)	

										case "Application React" :
										return(
											<a
							href={props.codeSource}
							className="btn m-3"
							target="_blank"
							rel="noopener noreferrer"
						>
							Code source
						</a>

										)
										case "Application Mobile" :
										return(
											<>
											<a href={props.url} 
						className="btn m-3" 
						target="_blank" 
						rel="noopener noreferrer">
							Détail sur l'apli
						</a>
											<a
							href={props.codeSource}
							className="btn m-3"
							target="_blank"
							rel="noopener noreferrer"
						>
							Play Store
						</a>
						</>

										)
										default:
											return(
												<>
												<a href={props.url} 
						className="btn m-3" 
						target="_blank" 
						rel="noopener noreferrer">
							Voir le projet
						</a>
						<a
							href={props.codeSource}
							className="btn m-2"
							target="_blank"
							rel="noopener noreferrer"
						>
							Code source
						</a>
						</>

							)

							}
							})()
							}
						
					</div>
						)
					}
					<div className="text-center">
						<i onClick={()=>{setToggel(!toggel)}} style={{color:'#41A0CC', transition:"2s", cursor:"pointer"}} className={`fas ${toggel ? "fa-angle-up":"fa-angle-down"} fa-2x`}></i>
					</div>
				</div>
			</div>
			<div className={show?"viewvideo active": "viewvideo "}>
					
			<video onClick={showVideo} width="500" height="360" controls autoPlay={true}>
				<source src="./assets/projetSymfony.mov" type="video/mp4" />
			</video>
			
			</div>
		</div>
	);
}

export default Listeprojets;
