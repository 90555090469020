import React from 'react';
import {voyagesData} from '../data/natureData';
import Cardnature from './Cardnature';

function Nature() {
    const dataNature = {
        nature: voyagesData
    };
    //console.log(dataNature);
    let {nature} = dataNature;
    
    return (
        <>
        <h2 className=" text-uppercase text-center titrevoyage">Voyages & Nature</h2>
        <div  className="cardnature ">
				{nature.map((props) => {
					return <Cardnature key={props.id} titre={props.titre} contenu={props.contenu}
                    photo={props.photo}/>;
				})}
                <footer className ="footHome">
        		<div className="container">
       			 <p>Copyright &copy; 2021 CV - TAKKA Karim - Tous droits réservés.</p>
        		</div>
    		</footer>
			</div>

        </>
    )
}

export default Nature
