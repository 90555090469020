import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Competences from './pages/Competences';
import Contact from './pages/Contact';
import Experiences from './pages/Experiences';
import Formations from './pages/Formations';
import Portfolio from './pages/Portfolio';
import NotFound from './pages/NotFound';
import { Navigation } from './components/Navigation';
import Ancre from './components/Ancre';

function App() {
	return (
		<BrowserRouter>
    <Navigation></Navigation>
			<Switch>
        <Route path = "/" exact component={Home}/>
        <Route path = "/competences" exact component={Competences}/>
        <Route path = "/contact" exact component={Contact}/>
        <Route path = "/experiences" exact component={Experiences}/>
        <Route path = "/formations" exact component={Formations}/>
        <Route path = "/projets" exact component={Portfolio}/>
        <Route path="*" exact component={NotFound}/>
      </Switch>
      <Ancre/>
		</BrowserRouter>
     
	);
}

export default App;
