export const sportData = [
   {
       id:1,
      titre:"Sport & Loisirs",
      contenu : "Courir, randonnée, natation et les sensations fortes comme l'escalade, le canyoning et le parapente",
      class:"clip clip1",
      image:"./assets/parapent1.JPG"
},
{
    id:2,
   titre:"Sport  & Loisirs",
   contenu : "Courir, randonnée, natation et les sensations fortes comme l'escalade, le canyoning et le parapente",
   class:"clip clip2",
   image:"./assets/parapent2.JPG"
}

];

