import React, { useState } from 'react';

function Cardnature(props) {
	//console.log(props.photo);
    const [ toggleImg, setToggImg ] = useState(false);
    const toggleClickImg = ()=>{
        setToggImg(!toggleImg)
    }
	return (
        <div key={props.id} className="box">
            <div onClick={toggleClickImg} className={`imgBx ${toggleImg ? 'active':''} `}>
                <img className="img-fluid" src={props.photo} alt="" />
                <h3>Fermer <i className="far fa-times-circle"></i></h3>
                <div className="content">
                    <h4>{props.titre}</h4>
                    <p>{props.contenu}</p>
                </div>
            </div>
        </div>
	);
}

export default Cardnature;
