export const languageData = [
    {
        id:1,
        language : 'Javascript',
        exp: 64,
        logo : 'fab fa-js fa-4x'
        
    },
    {
        id:2,
        language : 'TypeScript',
        exp: 30,
        logo : 'fab fa-js-square fa-4x'
        
    },
    {
        id:3,
        language : 'PHP',
        exp: 45,
        logo : 'fab fa-php fa-4x'
        
    },
    {
        id:4,
        language : 'HTML/CSS',
        exp: 80,
        logo : 'fab fa-css3-alt fa-4x'
        
    },
    {
        id:5,
        language : 'Nodejs',
        exp: 38,
        logo : 'fab fa-node-js fa-4x'
        
    },
    {
        id:6,
        language : 'CMS/WordPress',
        exp: 58,
        logo : 'fab fa-wordpress-simple fa-4x'
        
    }
];

export const frameworksData = [ 
    {
        id:1,
        language : 'React',
        exp: 62,
        logo : 'fab fa-react fa-4x'
    },
    {
        id:2,
        language : 'Angular',
        exp: 40,
        logo : 'fab fa-angular fa-4x'
    },
    {
        id:3,
        language : 'Symfony',
        exp: 53,
        logo : 'fab fa-symfony fa-4x'
    },
    {
        id:4,
        language : 'Bootstrap',
        exp: 71,
        logo : 'fab fa-bootstrap fa-4x'
    },
    {
        id:5,
        language : 'Sass',
        exp: 48,
        logo : 'fab fa-sass fa-4x' 
    }

];

export const bddData = [
    {
        id:1,
        language:'MySQL',
        exp : 60,
        photo : './assets/mySql.png'
    },
    {
        id:2,
        language:'MongoDB',
        exp : 45,
        photo : './assets/mongoDB.png'
    }
]

export const autreskillsData = [
    {
        id:1,
        language:'Git et GitHub Desktop',
        exp : 50,
        logo : 'fab fa-git-square fa-4x' 
    },
    {
        id:2,
        language:'Maintenance Hardware',
        exp : 74,
        logo : 'fas fa-cogs fa-4x' 
    },
    {
        id:3,
        language:'SEO',
        exp : 60,
        logo : 'fas fa-search fa-4x'
    },
    {
        id:4,
        language:'Analytics et Search console ',
        exp : 54,
        logo : 'fab fa-google fa-4x'
    },
    {
        id:5,
        language:'Environnement Microsoft',
        exp : 80,
        logo : 'fab fa-windows fa-4x' 
    },
    {
        id:6,
        language:'PhotoShop',
        exp : 38,
        logo : 'fas fa-file-image fa-4x'
    }
]