import React from 'react';
import Hobbies from '../components/Hobbies';
import Photo from '../components/Photo';
import Nature from '../components/Nature';


export default function Home() {
	return (
		<>
		<div id="ancre" className="container-fluid pages home">
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-12 col-md-4 margphoto">
						<span className="random-circle3bis">
							<br />PHP
						</span>
						<span className="random-circle3">
							<br />
							<br />
							<br />
							<br />Javascript
						</span>
						<Photo />
						<span className="random-circle4">HTML/CSS</span>
						<span className="random-circle5bis">
							<br />
							<br />
							<br />
							<br />Angular
						</span>
						<span className="random-circle5">
							<br />
							<br />
							<br />Nodejs<br />
						</span>
					</div>
					<div className="col-sm-12 col-md-8">
						<span className="random-circle2">
							<br />React
						</span>
						<h1 className="text-center m-2 p-2 titreHome">Qui suis-je ?</h1>
						<p>
						Eh bien je m’appelle  <strong> Karim TAKKA </strong> je vis à Paris,
						passionné depuis tout jeune par les sciences, aussi par l'univers du web et de nouvelles 
						technologies, j’ai grandi avec Windows 98 et les jeux vidéo 🎮, 
						j’ai donc vu l’arrivée du web et de son expansion fulgurante et surtout son impact 
						sur la vie quotidienne qui a touché tous les domaines <b>(social, économique et culturelle)</b>.</p>
						<h3>Mon parcours...</h3>
						<p>
						J’ai eu plusieurs parcours avant de <b>devenir développeur.</b> <br/>
						Je suis arrivée sur ce bout de chemin, peut-être pour certains tardivement, 
						mais au bon moment pour moi, car j’estime qu’avoir un <b>parcours riche</b> dans 
						plusieurs domaines nous donnent une vision plus large des choses, 
						ce que je pense êtres <b> un atout </b> pour les entreprises et dans le monde moderne que 
						nous vivons, on sera amené à apprendre et à évoluer encore probablement jusqu’à la fin de notre vie…
						</p>

						<p>	Après plusieurs années d’expériences dans le domaine de l’industrie agro-alimentaire et
							chimique puis dans la maintenance informatique puis dans la vente et pour finir gérent et responsable
							<b> webmaster</b> dans le <b>E-commerce</b>.<br /> Aujourd'hui je viens de finir ma
							reconversion professionnelle après une formation d'un an chez l'institut
							<a
								href="https://www.ifocop.fr/formations-metiers/web-digital/developpeur-dapplications-mobiles-et-web/"
								target="blank"
							>
								&nbsp;IFOCOP&nbsp;
							</a>
							de Paris et l'obtention du&nbsp;
							<b>titre RNCP (niveau 5) développeur de site web et d’applications mobiles</b>, ce qui m’a
							déjà permis d’apprendre beaucoup de nouvelles technologies et même de réaliser ce CV 📃 en
							ligne.<br /> Puis j'ai effectué un <b>bootcamp </b>
							de trois mois chez
							<a href="https://lapasserelle.school/" target="blank">
								&nbsp;la passerelle
							</a>
							, que j'ai terminé avec succès.</p> 
							<h3>Pourquoi devenir développeur...?</h3>
							<p>
							Après plusieurs années dans le E-commerce, je me suis beaucoup intéressé au métier de développeur et la façon de créer des sites web. 
J’ai découvert que c’est un domaine passionnant, qui est tout le temps en évolution et qui répond à des besoins concrets. <br/>
Ce qui me plait le plus c’est de créer quelque chose en partant de zéro et de se sentir utile 🤗, on peut dire que le développeur c’est l’artisan 🛠 du web.

							</p>

							<h3>Aujourd'hui...</h3>

							<p>
							Actuellement je cherche <b>un poste de développeur</b>, motivé pour apprendre, autonome, curieux, également rigoureux, je suis prêt à mettre toutes mes compétences à votre service.<br/> Disponible, je peux vous rencontrer afin de vous prouver mes motivations et vous expliquer mon parcours.<br />
							Je vous souhaite une bonne navigation !💻 😉
						</p>
						<span className="random-circle text-center text-light">
							<br />
							<br />SQL<br />Symfony
						</span>
					</div>
				</div>
			</div>

			<div className="row bgsection ">
				<div className="clippy"></div>
				<div className="clippy2"></div>
				<div className="container">
					<h2 className="text-uppercase text-center mt-3 languagetitle">compétence linguistique</h2>
					
					<div className="row languesCard mt-5">
						<div className="col-md-5 langues ">
							<h3>Anglais</h3>
						</div>
						<div className="col-md-7 star">
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
							<i className="far fa-star fa-lg m-2" />
							<i className="far fa-star fa-lg m-2" />
						</div>
						<div className="col-md-5 langues ">
							<h3>Français</h3>
						</div>
						<div className="col-md-7 star">
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
						</div>
						<div className="col-md-5 langues ">
							<h3>Arabe</h3>
						</div>
						<div className="col-md-7 star">
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
						</div>
						<div className="col-md-5 langues ">
							<h3>Berbère</h3>
						</div>
						<div className="col-md-7 star">
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
							<i className="fas fa-star fa-lg m-2" />
						</div>
					</div>
					<div className="row mt-5">
						<h2 className=" text-uppercase text-center languagetitle">Hobbies</h2>
						<div className="col-xs-12 mb-5 text-center">
						 <Hobbies /> 
						</div>
					</div>
					
				</div>
				<Nature/>
			</div>
			
		</div>
		
		</>
	);
}
