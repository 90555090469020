import React from 'react';
import Tilte from 'react-vanilla-tilt';

function Photo() {
	return (
		<div className="containerPhoto ">
			<Tilte option={{ max:25,
            scale:1.1,
            speed: 400}} style={{width:"80%", height:"100%", margin:"0 auto"}}>
				<div className="box">
					<div className="imgBx">
						<img className="img-fluid identite " src="./img/photo_identite.png" alt="img " />
					</div>
					<div className="contentBx">
						<h2>Karim TAKKA Développeur Web</h2>
					</div>
				</div>
			</Tilte>
		</div>
	);
}

export default Photo;
