import React from 'react';

function Progressbar(props) {
	let { language, frameworks, bdd, autres } = props;
	//console.log(frameworks);
	return (
		<div className="container">
			<div className="row">
				<div className="col-md-6">
					<div className="cardcompetences">
						<h3>{props.titre1}</h3>
						{language.map((props) => {
							return (
								<div key={props.id} className="boxCompetence">
									<div className="boxprogress">
										<i className={props.logo} />
										<h4>{props.language}</h4>
									</div>
									<div className="barVide">
										<div className="progress" style={{ width: `${props.exp}%` }}>
											<div
												className="progress-bar "
												role="progressbar"
												aria-valuenow="50"
												aria-valuemin="0"
												aria-valuemax="100"
											/>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="col-md-6 ">
					<div className="cardcompetences">
						<h3>{props.titre2}</h3>
						{frameworks.map((props) => {
							return (
								<div key={props.id} className="boxCompetence">
									<div className="boxprogress">
										<i className={props.logo} />
										<h4>{props.language}</h4>
									</div>
									<div className="barVide">
										<div className="progress" style={{ width: `${props.exp}%` }}>
											<div
												className="progress-bar "
												role="progressbar"
												aria-valuenow="50"
												aria-valuemin="0"
												aria-valuemax="100"
											/>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="col-md-6 " style={{ marginBottom: '20px' }}>
					<div className="cardcompetences">
						<h3>{props.titre3}</h3>
						{bdd.map((props) => {
							return (
								<div key={props.id} className="boxCompetence">
									<div className="boxprogress">
										<img
											className="img-fluid"
											style={{ width: '15%', margin: '10px, 0px' }}
											src={props.photo}
											alt=""
										/>
										<h4>{props.language}</h4>
									</div>
									<div className="barVide">
										<div className="progress" style={{ width: `${props.exp}%` }}>
											<div
												className="progress-bar bg-info"
												role="progressbar"
												aria-valuenow="50"
												aria-valuemin="0"
												aria-valuemax="100"
											/>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
				<div className="col-md-6 " style={{ marginBottom: '200px' }}>
					<div className="cardcompetences">
						<h3>{props.titre4}</h3>
						{autres.map((props) => {
							return (
								<div key={props.id} className="boxCompetence">
									<div className="boxprogress">
										<i className={props.logo} />
										<h4>{props.language}</h4>
									</div>
									<div className="barVide">
										<div className="progress" style={{ width: `${props.exp}%` }}>
											<div
												className="progress-bar "
												role="progressbar"
												aria-valuenow="50"
												aria-valuemin="0"
												aria-valuemax="100"
											/>
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Progressbar;
