import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';

export const Navigation = () => {
	const [ toggleMenu, setToggleMenu ] = useState(false);
	const [ classScroll, setClassScroll ] = useState('navigation');
	const toggleNavSmallScreen = () => {
		setToggleMenu(!toggleMenu);
	};

	useEffect(() => {
		const effetScroll = () => {
			if (window.scrollY > 0) {
				setClassScroll('navigation sticky');
			} else {
				setClassScroll('navigation');
			}
		};
		window.addEventListener('scroll', effetScroll);
		return () => {
			window.removeEventListener('scroll', effetScroll);
		};
	}, []);

	return (
		<div className={classScroll}>
			<img className="banner" src="./img/karim_takka_dev_web.jpg" alt="Karim takka développeur" />
			<Logo />
			<div className="scroll">
			<span>Scroll</span>
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="50" height="50">
        <g className="nc-icon-wrapper" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
            transform="translate(0.5 0.5)" fill="#111111" stroke="#111111">
            <g className="nc-loop_mouse-64">
                <path fill="none" stroke="#ffee" strokeMiterlimit="10"
                    d="M38,62H26 c-6.6,0-12-5.4-12-12V14c0-6.6,5.4-12,12-12h12c6.6,0,12,5.4,12,12v36C50,56.6,44.6,62,38,62z" />
                <path id="molette" data-color="color-2" stroke="#76BBDA" fill="none" strokeMiterlimit="10"
                    d="M32,27L32,27c-1.65686,0-3-1.34314-3-3 V14c0-1.65685,1.34314-3,3-3H32c1.65685,0,3,1.34314,3,3V24C35,25.65686,33.65686,27,32,27z"
                    data-cap="butt" transform="translate(0 4.531871999999974)" />
            </g>
        </g>
    </svg>

			</div>
			
			<nav>
				<ul className={`liste ${toggleMenu ? 'toggle' : ''}`}>
					<li className="propss">
						<NavLink className="nav-link" exact to="/" activeClassName="nav-active">
							Accueil
						</NavLink>
					</li>
					<li className="propss">
						<NavLink className="nav-link" exact to="/competences" activeClassName="nav-active">
							Compétences
						</NavLink>
					</li>
					<li className="propss">
						<NavLink className="nav-link" exact to="/experiences" activeClassName="nav-active">
							Expériences Professionnelles
						</NavLink>
					</li>
					<li className="propss">
						<NavLink className="nav-link" exact to="/formations" activeClassName="nav-active">
							Formations
						</NavLink>
					</li>
					<li className="propss">
						<NavLink className="nav-link" exact to="/projets" activeClassName="nav-active">
							Portfolio
						</NavLink>
					</li>
					<li className="propss">
						<NavLink className="nav-link" exact to="/contact" activeClassName="nav-active">
							Contact
						</NavLink>
					</li>
				</ul>
			</nav>
			<button onClick={toggleNavSmallScreen} className="btn">
				<i className={toggleMenu ? 'fas fa-times fa-lg' : 'fas fa-hamburger fa-lg'} />
			</button>
		</div>
	);
};
