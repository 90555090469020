export const experienceData = [
    {
        id:1,
        titre : "Dévloppeur Web",
        soustitre:"Activ'partners | 2020 (PARIS)",
        contenu :
         [
            'Maintenance et refonte de sites web : CMS WordPress (PHP - Js - MySql)',
            "Administrer l'hébergement et la base de données (OVH, FileZilla... ) " ,
            "Sécurité: tester les erreurs sur notre site (page orpheline, lien mort...),scanner notre site (malwares), sauvegarde et sécuriser la Base de données (Injection SQL). ",
            'Référencement (SEO) : référencement naturel, google search console, google trends et google page speed insight...',
            "Méthode agil : collaborer avec l'équipe webmarketing grâce aux données récoltées (Google analytics, Google AdWords)",
            "Projet annexe : Créer et mettre en ligne une application mobile sur Google play store."
        ]
    },
    {
        id:2,
        titre : "RESPONSABLE WEBMASTER",
        soustitre:"SARL IBC | 2013 - 2019 ( PARIS )",
        contenu : ["Co-gérant : responsable des ventes et achats ",
            "Gestion et supervision des différentes plateformes de vente (Amazon, Cdiscount, FNAC, Priceminister et BackMarket).",
    "Intégration de produits dans les différentes marketplaces.", "Communication: campagne de promotions, solde, mailing..."]
    },
    {
        id:3,
        titre : "TECHNICIEN INFORMATIQUE",
        soustitre:"SARL PCPACK | 2011 - 2013 (CLICHY 92)",
        contenu : ['Maintenance informatique Hardware et Software.',
        "Installation d'OS Windows et Apple.",
        "Récupération de données.",
        "Création de clone/copie du système. ",
        "Remplacemnt de pièces : Mémoire RAM; Disque dur; composants..."
    ]
    },
    {
        id:4,
        titre : "RECHERCHE ET ANALYSE",
        soustitre:"LABORATOIRE DE RADIOCHIMIE SCIENCES ANALYTIQUES | 2010 - 2011 ( NICE 06 )",
        document : './assets/Rapport_M2_chimie.pdf',
        contenu : ['Études du piégeage de polluants présents dans les eaux sur des solides naturels.',
        "Élaboration d'un filtre prototype contre l'Arsenic avec des oxydes de fer naturels."
    ]
    },
    {
        id:5,
        titre : "AUDITEUR QUALITÉ AGROALIMENTAIRE",
        soustitre:"LAITERIE DANONE | 2009",
        document : './assets/mémoire_M2_biotechnologie.pdf',
        contenu : ['Contrôle du système qualité HACCP et les Normes ISO.',
            "Analyse micro-biologique et physico-chimique."
    ]
    }
]