import React from 'react';
import Footer from '../components/Footer';

const NotFound = () => {
    return (
        <div className="pages">
           <h3>Erreur 404 Page not found</h3> 
           <Footer></Footer>
        </div>
    )
}

export default NotFound;
