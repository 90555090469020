import React from 'react';
import Footer from '../components/Footer';
import Cardformation from '../components/formationsComponents/Cardformation';
import { formationData } from '../data/formationsData';

const Formations = () => {
	let data = {
		formations: formationData
	};
	// console.log(data.formations);
	let { formations } = data;
	return (
		<div id="ancre" className="pages formation">
			<div className="headerFormation"></div>
			<h1>Formations</h1>
            <div className="container">
            <div className="formation">
            {formations.map((props) => {
				return (
					<Cardformation
						key={props.id}
						logo={props.logo}
						titre={props.titre}
						etablissement={props.etablissement}
						contenu={props.contenu}
						realisation={props.realisation}
						photo={props.photo}
					/>
				);
			})}
            </div>
            </div>
			
            <div className="vide" />
			<Footer />
		</div>
	);
};

export default Formations;
