import React, { useEffect } from 'react';
import { gsap } from 'gsap/all';
import { ScrollTrigger } from 'gsap/all';
import { useRef } from 'react';
gsap.registerPlugin(ScrollTrigger);

function Ancre() {

    const retourHome = useRef();
    useEffect(()=>{
        gsap.from(retourHome.current, {
            visibility:"hidden",
            duration:2,
            transition:1,
            scrollTrigger:{
                trigger:retourHome.current,
                start:'top center',
                scrub: true
            }
        })
    }, []);

    return (
        <div ref={retourHome} className="ancre">
            <a href="#ancre" rel="noopener noreferrer">
            <i className="fas fa-arrow-circle-up fa-2x"></i>
            </a>
            
        </div>
    )
}

export default Ancre
