export const portfolioData =[
    {
        id:1,
        titre : "Réalisation d'une API NodeJs",
        soustitre:"Projet fil rouge partie back-end :",
        contenu : "Réalisation d'une API avec NodeJs, connectée à une base de données NoSQL Mongodb, hébergé sur Heroku",
        logo : 'fab fa-node-js fa-3x m-1',
        url : "https://cryptic-hollows-87415.herokuapp.com/articles",
        codeSource:"https://github.com/rimka-dev/Database-MongoDB",
        img:"./assets/api.png",
        document:"./assets/apiNode_karim_Takka.pdf",
        techno:['NodeJS', 'All']
    },
    {
        id:2,
        titre : "Projet Angular connecter avec notre API",
        soustitre:"Projet fil rouge partie front-end :",
        contenu : "Connecter notre API Node à la partie client réalisé avec Angular, puis interagir avec la base de données et réaliser toutes les requêtes du CRUD",
        logo : 'fab fa-angular fa-3x m-1',
        url : "https://rimka-dev.github.io/angular-project/",
        codeSource:"https://github.com/rimka-dev/angular-project",
        img:"./assets/codage-web.png",
        document:"",
        techno:['Angular', 'All']
    },
    {
        id:3,
        titre : "Projet Symfony SQL",
        soustitre:"Projet bootcamp site d'annonces :",
        contenu : "Réaliser un site d'annonce de colocation entre les seniors, utilisation du framework Symfony avec doctrine (ORM) relier à une base de données MySQL ",
        logo : 'fab fa-symfony fa-3x m-1',
        url : "./assets/projetSymfony.mov",
        codeSource:"https://github.com/rimka-dev/Symfony_project",
        img:"./assets/developpeur-symfony.png",
        techno:['PHP', 'All']
    },
    {
        id:4,
        titre : "Projet WordPress",
        soustitre:"Site E-commerce :",
        contenu : "Réaliser un site E-commerce complet et opérationnel pour une entreprise de vente de masques de protections",
        logo : 'fab fa-wordpress-simple fa-3x m-1',
        url : 'https://lemasquefacial.fr',
        codeSource:"",
        img:'./assets/online-shopping.png',
        techno:['WordPress','SEO', 'All']
    },
    {
        id:5,
        titre : "Responsive web design",
        soustitre:"Maquette responsive :",
        contenu : "Réaliser une maquette responsive avec la technique du mobile first en utilisant le système de grille Bootstrap",
        logo : 'fab fa-bootstrap fa-3x m-1',
        url : 'https://rimka-dev.github.io/responsive_web_design/',
        codeSource:"https://github.com/rimka-dev/responsive_web_design",
        img : "./assets/responsive.png",
        techno:['Bootstrap','All']
    },
    {
        id:6,
        titre : "Référencement SEO",
        soustitre:"Référencement et refont de sit web :",
        contenu : "Référencement de trois sites web du groupe Activ'partners ainsi que la refont d'un de leur site principal ",
        logo : 'fas fa-search fa-3x m-1',
        url : 'https://www.activpartners.com/',
        codeSource:"https://www.agophore-institut.com/",
        img:"./assets/seo.png",
        document:"./assets/Dossier_Synthèse_Karim_TAKKA.pdf",
        techno:['WordPress','SEO', 'All']
    },
   
    {
        id:7,
        titre : "Application React",
        soustitre:"CV en ligne :",
        contenu : "Vous y êtes déjà ce site est entièrement fait avec React et princepalement avec les Hooks ",
        logo : 'fab fa-react fa-3x m-1',
        url : 'https://www.portfolio-k-takka.site/',
        codeSource:"https://github.com/rimka-dev?tab=repositories",
        img:"./assets/react.png",
        techno:['React','All']
    },
    {
        id:8,
        titre : "Application Mobile",
        soustitre:"Application mobil Android :",
        contenu : "Création et déploiement d'une première version d'une application mobile compatible Android",
        logo : 'fab fa-android fa-3x m-1',
        url : 'https://www.agophore-institut.com/p-f-i-09/',
        codeSource:"https://play.google.com/store/apps/details?id=appinventor.ai_activpartners365.PFI09_version_3",
        img:"./assets/play.png",
        techno:['Mobile','React','All']
    },

]