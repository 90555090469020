import React from 'react';
import { sportData } from '../data/hobbiesData';
import Cardhobbies from './Cardhobbies';

function Hobbies() {
	const data = {
		sports: sportData
	};
	//console.log(data);
	let { sports } = data;
	return (
		<div className=" cardhobbies ">
			{sports.map((props) => {
				return (
					<Cardhobbies
						key={props.id}
						titre={props.titre}
						class={props.class}
						contenu={props.contenu}
						image={props.image}
					/>
				);
			})}
		</div>
	);
}

export default Hobbies;
