import React from 'react';

export default function Cardcompetences(props) {
	let { titre, soustitre, contenu, id, document } = props.props;
	//console.log(props);
	return (
		<div key={id} className= " card my-4 ">
			<div className="card-body">
				<h2 className="titire">{titre}</h2>
				<h4>{soustitre}</h4>
				<ul key={id}>
					{contenu.map((index) => {
						return <li key={index}>{index}</li>;
					})}
				</ul>
				{
					(id===4 || id===5)&&(
						<a className='btn btn-primary' href={document} target="_blank" rel="noopener noreferrer">Document</a>
					)
				}
				
				
			</div>
		</div>
	);
}
