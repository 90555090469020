import React from 'react';
import Footer from '../components/Footer';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default function Contact() {
	return (
		<div id="ancre" className="pages contact">
			<div className="container">
				<div className="headercontact" >
				</div>
				<div className="contactBox">
					<h1 className="titrecontact">Contactez-moi</h1>
					<ul className="listeInfo">
						<li>
							<i className="far fa-id-card fa-3x" />
							<span>Mr. TAKKA Karim</span>
						</li>
						<li>
							<i className="fas fa-map-marker-alt fa-3x" />
							<span>Paris 75017 </span>
						</li>
						<li>
							<i className="fas fa-mobile-alt fa-3x " />
							<CopyToClipboard text="0650317849">
								<span
									style={{ cursor: 'pointer' }}
									onClick={() => {
										alert('Téléphone copié !');
									}}
								>
									06 50 31 78 49
								</span>
							</CopyToClipboard>
						</li>
						<li>
							<i className="fas fa-at fa-3x " />
							<CopyToClipboard text="k.takka01@gmail.com">
								<span
									style={{ cursor: 'pointer' }}
									onClick={() => {
										alert('Adresse mail copié !');
									}}
								>
									k.takka01@gmail.com
								</span>
							</CopyToClipboard>
						</li>
						<li>
							<a
								className="btn"
								href="./assets/CV DÉVELOPPEUR karim takka.pdf"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i className="far fa-file-pdf fa-3x" />
								<span>Télécharger CV </span>
							</a>
						</li>
					</ul>
				</div>
				<div className="socialNetwork">
					<div className="m-2">
						<a
							className="btn"
							href="https://www.linkedin.com/in/karim-takka-221016p/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fab fa-linkedin fa-3x m-1" />
							<span>LinkedIn </span>
						</a>
					</div>
                    <div className="m-2">
						<a
							className="btn"
							href="https://github.com/rimka-dev?tab=repositories"
							target="_blank"
							rel="noopener noreferrer"
						>
							<i className="fab fa-git-square fa-3x m-1" />
							<span>Github </span>
						</a>
					</div>
				</div>
			</div>
            <div className="vide">

            </div>
			<Footer />
		</div>
	);
}
