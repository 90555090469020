export const voyagesData = [
    {
        id:1,
        titre:"Ile Maurice",
        contenu : " j'aime découvrir de nouvelles destinations.",
        photo:"./assets/ile_maurice.jpg"
    },
    {
        id:2,
        titre:"Archipel des Maldives",
        contenu : " j'aime la nature et surtout les belles plages.",
        photo:"./assets/maldive.JPG"
    },
    {
        id:3,
        titre:"Ile Canarie",
        contenu : " j'aime découvrir de nouveaux paysages.",
        photo:"./assets/ile_canarie.jpg"
    },
    {
        id:4,
        titre:"République Dominicaine",
        contenu : " j'aime découvrir de nouvelles cultures.",
        photo:"./assets/republique_dominique.JPG"
    },
    {
        id:5,
        titre:"Nature et plongée (Raie manta)",
        contenu: "Ma passion c'est la nature la mer et la plongée.",
        photo:"./assets/raie_manta.JPG"
    },
    {
        id:6,
        titre:"Nature et plongée (Requin à pointe noire)",
        contenu: "j'aime découvrir les différents océans avec leurs incroyable biodiversité.",
        photo:"./assets/roquin.JPG"
    },
    {
        id:7,
        titre:"Nature et plongée (La tortue caouanne)",
        contenu: "Sensibiliser les gens à la protection de la nature.",
        photo:"./assets/tortue1.JPG"
    },
    
    {
        id:8,
        titre:"Nature et plongée (Poisson clown frenatus)",
        contenu: "Préserver notre planète et profiter de la beauté qu'offre peut nous offrir.",
        photo:"./assets/poissons.JPG"
    }


]