import React, { useEffect, useRef } from 'react';
import Cardcompetences from '../components/experienceComponents/Cardcompetences';
import Footer from '../components/Footer';
import { experienceData } from '../data/experiencesData';
import { gsap } from 'gsap/all';
import { ScrollTrigger } from 'gsap/all';
const EXperiences = () => {
	const data = {
		experiences: experienceData
	};
	//console.log(data);
	gsap.registerPlugin(ScrollTrigger);

	const cardexperience = useRef();
	const element = gsap.utils.selector(cardexperience);
	useEffect(
		() => {
			gsap.from(element('.card'), {
				x: -1200,
				background: '#42A0CC',
				opacity: 0.6,
				stagger: 0.6,
				delay: 0.5,
				duration: 2,
				ease: 'bounce'
			});
		},
		[ element ]
	);

	return (
		<div id="ancre" className="pages experiences">
			<div className="clippy"></div>
			<div className="clippy2"></div>
			<div className="container">
				<div ref={cardexperience} className="experienceOne">
					<h1 >Expériences</h1>
					{data.experiences.map((props) => {
						return <Cardcompetences key={props.id} props={props} />;
					})}
				</div>
				
				<div className="vide" />
			</div>
			<Footer />
		</div>
	);
};

export default EXperiences;
