import React from 'react';
import { useState } from 'react';
import Footer from '../components/Footer';
import Listeprojets from '../components/portfolioComponents/Listeprojets';
import { portfolioData } from '../data/portfolioData';

const Portfolio = () => {
	const data = {
		projets: portfolioData,
		radios: [
			{ id: 1, value: 'All' },
			{ id: 2, value: 'NodeJS' },
			{ id: 3, value: 'Angular' },
			{ id: 4, value: 'PHP' },
			{ id: 5, value: 'Bootstrap' },
			{ id: 6, value: 'WordPress' },
			{ id: 7, value: 'SEO' },
			{ id: 8, value: 'React' },
			{ id: 9, value: 'Mobile' }
		]
	};
	//console.log(data.projets)
	let { radios } = data;
	//console.log(radios);
	const [ selectedRadio, setSelectedRadio ] = useState('All');
		const handleRadio = (e) => {
			//console.log(e.target.value)
			let radio = e.target.value;
			setSelectedRadio(radio);
		};

	return (
		<div id="ancre" className="pages portfolio">
			<div className="headerPortfolio"></div>
			
			<h1 className="m-4">Projets</h1>

			<div className="container">
				<div className="listeradio">
					{radios.map((radio) => {
						return (
							<div key={radio.id} className="form-check form-check-inline m-2 ">
								<input
									className="form-check-input"
									type="radio"
									name="inlineRadioOptions"
									id="inlineRadio1"
									value={radio.value}
									checked={radio.value === selectedRadio}
									onChange={handleRadio}
								/>
								<label className="form-check-label" htmlFor="inlineRadio1">
									{radio.value}
								</label>
							</div>
						);
					})}
				</div>

				<div className="row">
					{data.projets.filter((item) => item.techno.includes(selectedRadio)).map((item) => {
						return (
							<Listeprojets
								key={item.id}
								titre={item.titre}
								soustitre={item.soustitre}
								contenu={item.contenu}
								logo={item.logo}
								url={item.url}
								codeSource={item.codeSource}
								img={item.img}
								document={item.document}
							/>
						);
					})}
				</div>
			</div>
			<div className="vide" />
			<Footer />
		</div>
	);
};

export default Portfolio;
