export const formationData = [
    {
        id:1,
        titre : 'Bootcamp développeur web front & back',
        etablissement : 'ECOLE LA PASSERELLE | PARIS 2021',
        contenu : 'Développeur web : front-end (JavaScript + React); Back-end (PHP/Symfony, MySQL)',
        realisation : "Réalisation d'un projet complet Symfony (Composer, Doctrine, MySQL, Twig) ",
        logo:'./assets/atestation.png',
        photo:"./assets/Attestation_formation_la_passerelle.jpg"
    },
    {
        id:2,
        titre : 'TITRE RNCP DE NIVEAU 5 DÉVELOPPEUR WEB ET WEB MOBILE',
        etablissement : 'INSTITUT IFOCOP DE PARIS XI | 2020',
        contenu : 'Développeur full-stack Js : front-end (Javascript + Angular); Back-end (Nodejs, MongoDB)',
        realisation :"Réalisation d'un projet Angular connecté a une API Nodejs",
        logo:'./assets/atestation.png',
        photo:"./assets/RNCP_developpeur.png"
    },
    {
        id:3,
        titre : 'CERTIFICAT D\'ACCOMPLICEMENT',
        etablissement : 'LINKEDIN LEARNING | 2021',
        contenu : 'L\'essentiel de Javascript',
        realisation :"",
        logo:'./assets/atestation.png',
        photo:"./assets/CertificatDaccomplissement_JavaScript.jpg"
    },
    {
        id:4,
        titre : 'CERTIFICAT D\'ACCOMPLICEMENT',
        etablissement : 'LINKEDIN LEARNING | 2021',
        contenu : 'L\'essentiel du HTML5',
        realisation :"",
        logo:'./assets/atestation.png',
        photo:"./assets/CertificatDaccomplissement_HTML5.jpg"
    },
    {
        id:5,
        titre : 'MASTER 2 EN CHIMIE',
        etablissement : 'UNIVERSITE SOPHIA ANTI POLIS DE NICE | OBTENU EN 2011',
        contenu : 'Option environnement et matériaux pour un développement durable',
        realisation :"Laboratoire de radio-chimie et analyse de l'environnement",
        logo:'./assets/diplome.png',
        photo:"./assets/master2chimie.png"
    },
    {
        id:6,
        titre : 'MASTER 2 EN BIOTECHNOLOGIE',
        etablissement : 'UNIERSITE DE BEJAIA ALGERIE | OBTENU EN 2009',
        contenu : 'Biotechnologie agro-ressources aliment et nutrition',
        realisation :"Effectuer chez DANONE Algérie",
        logo:'./assets/diplome.png',
        photo:"./assets/master2biotechnologie.jpg"
    }
]