import React from 'react'

function Cardhobbies(props) {
    //console.log(props);
    return (
        
            <div key={props.id} className={props.class}>
                <img className="img-fluid" src={props.image} alt="" />
                <div className="content">
                <h4>{props.titre}</h4>
                <p>{props.contenu}</p>
                </div>
            </div>
        
    )
}

export default Cardhobbies
